<template>
  <div class="icon"></div>
</template>

<style lang="less" scoped>
@import url('~@/common/styles.less');

.icon {
  display: inline-block;
  width: 4px;
  height: 14px;
  border-radius: 2px;
  overflow: hidden;
  background-color: @primaryColor;
}
</style>
